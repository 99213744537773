import React from 'react'
import Titles from './Titles'
import SingleImage from './SingleImage'
import '../assets/css/sections.css'

function Footer() {
  return (
    <div className="sections footer">
      <div>
        2022 Guipedrov Portfolio. Projeto Original. Direitos reservados com uso da Licença MIT.
      </div>
    </div>
  )
}

export default Footer